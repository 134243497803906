<template>
  <div class="wrapper">
    <div class="container">
      <!--页头-->
      <div class="page-header" style="line-height: 40px">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">隐私政策</h2>
        </div>
      </div>
      <p>更新日期: 2024年9月25日</p>
      <p>生效日期: 2020年1月1日</p>
      <br>
      <!--内容-->
      <div class="agreement-content" deep="2">
        <ul>
          <li>
            印工社（青岛）数字科技有限公司（以下简称“印工社”或“我们”）深知您的隐私对您的重要性，并充分尊重您的隐私。
            在同意向我们提交您的个人数据之前，请您仔细阅读并理解本《隐私政策声明》（以下简称“本声明”）。
            我们会按照本隐私权政策的规定使用和披露您的个人信息。除本隐私权政策另有规定外，
            在未征得您事先许可的情况下，我们不会将这些信息对外披露或向第三方提供。您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。
            本隐私权政策属于本应用服务使用协议不可分割的一部分。
          </li>

          <li>
            <h3>1. 适用范围</h3>
          </li>

          <li>
            <p>
              (a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息；
            </p>

            <p>
              (b)
              在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，
              包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
            </p>

            <p>
              (c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。
            </p>

            <p>
              您了解并同意，以下信息不适用本隐私权政策：
            </p>

            <p>
              (a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；
            </p>

            <p>
              (b)
              本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；
            </p>

            <p>
              (c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。
            </p>
          </li>

          <li>
            <h3>2. 我们收集的信息</h3>
          </li>

          <li>
            <p>
              我们将逐一说明我们收集的你的个人信息类型及其对应的用途，以便你了解我们针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。
            </p>
            <p>
              当你使用一些功能时，我们会在获得你的同意后，收集你的一些敏感信息，目前，我们不会主动共享或转让你的个人信息至第三方，你可以通过本指引所列途径访问、更正、删除你的个人信息。
            </p>
            <p>
              为了在本指引下收集你的信息，或者向你提供服务、优化我们的服务以及保障你的帐号安全，我们将需要向你索取相关的权限；其中的敏感权限例如摄像头、麦克风、相册等均不会默认开启，只有在你明确同意后才会向我们授权。
            </p>
            <p>
              需要特别说明的是，获得敏感权限是我们收集特定信息的必要而非充分条件。我们获得具体某一项敏感权限并不代表我们必然会收集你的相关信息；即使我们已经获得敏感权限，也仅在必要时、根据本指引来收集你的相关信息。
            </p>
            <p>
              我们向你提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了你的信息，我们会通过页面提示、交互流程、网站公告等方式另行向你说明信息收集的内容、范围和目的，以征得你的同意。
            </p>
<!--            <p>-->
<!--              请你注意,你使用服务过程中有可能个推消息推送SDK，其目的为App用户提供消息推送技术服务。-->
<!--            </p>-->
            <p>
              安卓端处理的信息：
            </p>
            <p>
              (a)设备信息、网络信息：必选信息：设备平台、设备厂商、设备品牌、设备型号及系统版本、OAID、AndroidID、WIFI连接信息、运营商信息 可选信息：IMEI、MAC、Advertising ID、Serial Number、IMSI、ICCID、IP地址、基站信息。用于生成唯一的推送目标ID（CID）和设备ID（GID），以此保证消息推送的准确下发和消息设备的准确识别。
            </p>
            <p>
              (b)位置相关信息：应急推送扩展功能的必选信息。应国家有关部门要求，为了提升移动互联网技术服务社会公益的功能，发挥 APP 消息推送在灾害预警方面的作用，当发生地震及气象等重大灾害时，通过开发者的APP 推送通道实时向受影响地区的用户下发相关的灾害通知信息。
            </p>
            <p>
              (c)应用列表信息：应急推送和链路调节器扩展功能的必选信息。应国家有关部门要求，为了提升移动互联网技术服务社会公益的功能，发挥 APP 消息推送在灾害预警方面的作用，当发生地震及气象等重大灾害时，通过开发者的APP 推送通道实时向受影响地区的用户下发相关的灾害通知信息。我们采⽤合并链路技术进⾏技术推送，当⼀个设备有多个APP的推送链路同时活跃时，我们会随机合并成⼀条链路，以达到为您省电省流量的⽬的，当一个设备有离线推送消息待下发的场景时，通过关联启动功能可以下发离线消息，您可以选择开启或关闭这项服务，当您关闭这项服务时，关联启动也同时会关闭，会导致电量和流量消耗加大的同时消息触达率也会降低。
            </p>
            <p>
              iOS端处理的信息：
            </p>
            <p>
              (a)设备信息：设备识别码（IDFA、IDFV）、设备平台、设备厂商用于生成唯一的推送目标ID（CID）和设备ID（GID），以此保证消息推送的准确下发和消息设备的准确识别。设备品牌、设备型号及系统版本用于优化通道资源的有效性，提升消息推送的到达率和稳定性。 （2）网络信息（包含IP地址、WIFI信息、基站信息、运营商信息）以及位置相关信息：为了最大程度保持网络连接的稳定性，建立长链接，我们需要了解设备的网络状态和变化，从而实现稳定连续的推送服务。 为保障我们推送服务的稳定运行、功能实现，使您能够使用和享受更多的功能及服务，我们的服务中会接入由合作方提供的软件开发工具包（SDK）、应用程序接口（API）[1] 。我们会对合作方获取信息的软件开发工具包（SDK）、应用程序接口（API）[2] 进行严格的安全检测，并与合作方约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
            </p>
            <p>
              使用目的：为用户提供应急推送等扩展功能。
            </p>
            <p>
              <a href="#/partners">查看合作方隐私政策</a>

            </p>
          </li>

          <li>
            <h3>3. 信息使用</h3>
          </li>

          <li>
            <p>
              (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，
              或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，
              其将被禁止访问包括其以前能够访问的所有这些资料。
            </p>

            <p>
              (b) 本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。
              任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
            </p>

<!--            <p>-->
<!--              (c)-->
<!--              为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，-->
<!--              或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。-->
<!--            </p>-->
          </li>

          <li>
            <h3>4. 信息披露</h3>
          </li>

          <li>
            <p>
              在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
            </p>

            <p>
              (a) 经您事先同意，向第三方披露；
            </p>

            <p>
              (b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
            </p>

            <p>
              (c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
            </p>

            <p>
              (d)
              如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；
            </p>

            <p>
              (e)
              如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
            </p>

            <p>
              (f)
              在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
            </p>

            <p>
              (g) 其它本应用根据法律、法规或者网站政策认为合适的披露。
            </p>
          </li>

          <li>
            <h3>5. 信息存储和交换</h3>
          </li>

          <li>
            本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。
          </li>

          <li>
            <h3>6. Cookie的使用</h3>
          </li>

          <li>
            <p>
              (a) 在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies
              ，以便您能登录或使用依赖于cookies的本应用平台服务或功能。
            </p>

            <p>
              (b)
              您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。
            </p>

            <p>
              (c) 通过本应用所设cookies所取得的有关信息，将适用本政策。
            </p>
          </li>

          <li>
            <h3>7. 信息安全</h3>
          </li>

          <li>
            <p>
              (a)
              本应用帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
            </p>

            <!--<p>
              (b) 在使用本应用网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对
            </p>-->
          </li>

          <li>
            <h3>8.本隐私政策的更改</h3>
          </li>

          <li>
            <p>
              (a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，
              哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
            </p>

            <p>
              (b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。
            </p>

            <p>
              为防止向第三方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。
            </p>
          </li>
          <li>
            <h3>9.如何联系我们</h3>
          </li>
          <li>
            <p>
              如果您对本隐私政策有任何疑问或需要进一步的信息，或者想要了解您的个人信息如何被使用、访问、更正或删除，或者有关于隐私的其他问题，您可以通过以下方式与我们联系：
              邮箱: ygs@cpct.net.cn。
            </p>
            <p>
              请在联系我们时提供足够的信息以便我们能够处理您的请求，包括您的姓名、联系方式以及您对我们服务的具体问题或请求。
            </p>
            <p>
              此外，如果您认为我们的处理方式不符合相关隐私保护法规，您有权向相关的数据保护机构提出投诉。
              请注意，为了保护您的隐私和安全，我们在处理您的请求时可能需要验证您的身份。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPage',
  data() {
    return {};
  },
  // beforeRouteEnter(to, from, next) {
  //   // console.log(to)
  //   // 改变网页、面包屑标题内容
  //   next(vm => {
  //     to.meta.title = '隐私政策'
  //   })
  // },
  created() {
    // console.log(this.$route);
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

.container {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.agreement-content {
  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 20px;
    }
  }

  h3,
  h4,
  p {
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 500px) {
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
